import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Master from "./partials/master";
import { CircularProgress, Typography, Box } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "./UserContext";

const CircularProgressWithLabel = ({ value }) => {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
                variant="determinate"
                value={value}
                sx={{
                    color: "#09a381",
                    width: "120px !important",
                    height: "120px !important",
                }}
                size={120}
                thickness={6}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="h6" component="div" color="white">
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

const DefaultDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const user = useUser();
    const userData = user?.user;
    const residentData = user?.residentDetails;
    const residentError = user?.residentError;
    const residentStatus = user?.residentStatus;
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (!token) {
            navigate("/login");
            return;
        }

        // Check if specific keys exist in localStorage
        const hasRequiredLocalStorageData =
            localStorage.getItem("resident") &&
            localStorage.getItem("user") &&
            localStorage.getItem("residentTimestamp") &&
            localStorage.getItem("userTimestamp") &&
            localStorage.getItem("token");

        if (hasRequiredLocalStorageData) {
            setLoading(false); // Hide the loader
            setProgress(100); // Set progress to 100%
            return;
        }
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev < 50) return prev + 10;
                if (prev < 100) return prev + 0.5;
                return 100;
            });
        }, 300);

        return () => {
            clearInterval(interval);
        };
    }, [token, navigate]);

    useEffect(() => {

        if (residentData.length > 0 && residentStatus === 200 && userData) {
            setLoading(false);
            setProgress(100);
        }

        if (residentData.length === 0 && residentStatus === 200 && userData) {
            setLoading(false);
            setProgress(100);
        }

        if (residentError) {
            setLoading(false);
            setProgress(100);
        }
    }, [residentStatus, userData, residentError]);


    return (
        <Master>
            {loading ? (
                <div className="loader-overlay">
                    <CircularProgressWithLabel value={progress} />
                    <span className="logout-text">Loading Please wait...</span>
                </div>
            ) : (
                <div className="content">
                    <div className="dashboard-content">
                        <div className="dash-heading">
                            <h1 className="dash-heading-text">
                                Welcome to AlfBoss, {userData?.user?.first_name} {userData?.user?.last_name}!
                            </h1>
                        </div>
                        <div className="dash-para">
                            <p className="para-text">
                                We’re excited to have you on board. Your dashboard is now ready.
                            </p>
                            <p className="para-text">
                                Start managing your resident data with ease and discover the tools designed to simplify your workflow.
                            </p>
                        </div>
                    </div>
                    <div className="dashboard-section">
                        <div className="dashboard-card">
                            <div className="col-md-4">
                                <div className="card">
                                    <a href="/resident-dashboard" className="card-link">
                                        <div className="card-body">
                                            {/* <i class="fa-solid fa-users-line icon-size"></i>
                                            <h5 className="card-title">Resident Dashboard</h5>
                                            <p className="card-text">Manage your Resident Data efficiently.</p> */}
                                            <img src="/images/resident-dash.png" className="dashboard-images" alt="Resident Dashboard" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <a href="/dashboard" className="card-link">
                                        <div className="card-body">
                                            {/* <i class="fa-solid fa-users icon-size"></i>
                                            <h5 className="card-title">Employee Dashboard</h5>
                                            <p className="card-text">Manage your Employee Data efficiently.</p> */}
                                            <img src="/images/employee-dash.png" className="dashboard-images" alt="Staff Compliance" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <a href="/tutorial" className="card-link">
                                        <div className="card-body">
                                            {/* <i class="fa-solid fa-circle-question icon-size"></i>
                                            <h5 className="card-title">Help</h5>
                                            <p className="card-text">Get assistance and FAQs.</p> */}
                                            <img src="/images/tutorial-dash.png" className="dashboard-images" alt="Tutorial" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dashboard-section">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="card-body">
                                    <h5 className="card-title mb-4">Frequently Asked Questions</h5>

                                    <div className="accordion" id="faqAccordion">
                                        {/* Account & Login Issues */}
                                        <div className="accordion-item mb-3">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    <i className="fa-solid fa-user-lock me-2 text-primary"></i> <span className="faq-heading">Account & Login Issues</span>
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <ol className="ps-3">
                                                        <li className="faq-list"><strong>I didn’t receive the invite email:</strong> Check your inbox and spam folder. The invite is sent to the registered email.</li>
                                                        <li className="faq-list"><strong>Invite link expired or invalid:</strong> Ask your admin to resend it. It remains valid for 30 days.</li>
                                                        <li className="faq-list"><strong>How to sign up without an invite:</strong> Only Facility Owners can use the signup page. Others need admin setup.</li>
                                                        <li className="faq-list"><strong>Forgot username:</strong> Provide your email and support will send your credentials.</li>
                                                        <li className="faq-list"><strong>Forgot password:</strong> Use "Forgot Password" or contact admin/support for reset.</li>
                                                        <li className="faq-list"><strong>Reset link not working:</strong> Try in an incognito window or request a new one.</li>
                                                        <li className="faq-list"><strong>Invalid credentials error:</strong> Double-check for typos in username or password.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Course & Certification Issues */}
                                        <div className="accordion-item mb-3">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <i className="fa-solid fa-graduation-cap me-2 text-success"></i> <span className="faq-heading">Course & Certification Issues</span>
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <ol className="ps-3">
                                                        <li className="faq-list"><strong>Completed course but no certificate:</strong> It's emailed to the Facility Admin and available in employee details.</li>
                                                        <li className="faq-list"><strong>Paid but course not showing:</strong> Check Facility Details or contact support.</li>
                                                        <li className="faq-list"><strong>Download certificate:</strong> Admins can download it from the profile.</li>
                                                        <li className="faq-list"><strong>Access certificate later:</strong> Yes, it's stored securely. Request it anytime.</li>
                                                        <li className="faq-list"><strong>Wrong name on certificate:</strong> Admins can update your name. We’ll reissue the certificate.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>

                                        {/* General Usage Issues */}
                                        <div className="accordion-item mb-3">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <i className="fa-solid fa-gears me-2 text-warning"></i>  <span className="faq-heading">General Usage Issues</span>
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <ol className="ps-3">
                                                        <li className="faq-list"><strong>Dashboard not visible:</strong> Use the left-hand menu for navigation.</li>
                                                        <li className="faq-list"><strong>Website is slow or down:</strong> Visit our <a href="https://stats.uptimerobot.com/diLKdgcrLb" target="_blank" rel="noreferrer">Uptime Status</a>.</li>
                                                        <li className="faq-list"><strong>Module shows incomplete:</strong> Contact support with a screenshot for verification.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </Master>
    );
};

export default DefaultDashboard;
