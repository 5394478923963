import React,{ useEffect,useState } from "react";
import '../../assets/css/style.css';
import Header from "./header";
import Sidebar from "./sidebar";
import Footer from "./footer";
import MarqueeMessage from "./marquee";

const Master = ({ children }) => {

    return (
        <div className="main-container">
            <div className="main-header">
                <div className="header">
                    <Header />
                </div>
            </div>
            <div className="content-container">
                <div className="sidebar">
                    <Sidebar />
                    
                </div>
                <div className="content-section">
                    <div className="content">
                        {/* <MarqueeMessage /> */}
                        { children }
                    </div>
                    <Footer />
                </div>
            </div>
            {/* <div className="footer">
                
            </div> */}
        </div>
    );

};

export default Master;
