import { Toast } from "bootstrap";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
      localStorage.clear();
      navigate("/login"); 
      toast.success("You have successfully logged out.");

  }, [navigate]);

  return (
    <div className="logout-page">
      <ToastContainer />
    </div>
  );
};

export default Logout;
