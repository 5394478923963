import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PasswordReset = () => {
    const cacheBuster = Date.now();
    const [email, setEmail] = useState("");
    const [sentMessage, setSentMessage] = useState("");

    const sendEmail = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/users/reset_password/?cacheBuster=${cacheBuster}`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: email }),
            });
    
            if (response.ok) {
                const text = await response.text(); // Get response as text
                let data;
                try {
                    data = text ? JSON.parse(text) : {}; // Parse JSON only if text exists
                } catch (error) {
                    toast.error("JSON parsing error:", error);
                    data = {}; // Default to empty object
                }
                setSentMessage("Please check your email for the password reset link");
            } else {
                alert("Invalid credentials or an error occurred.");
            }
        } catch (error) {
            alert("Something went wrong. Please try again.");
        }
    };
    
    useEffect(() => {
        // Clear localStorage when the component is mounted
        localStorage.clear();
      }, []);

    return (
        <div className="page-signin ng-scope">
            <ToastContainer />
            <div className="signin-body">
                <div className="row">
                    <div className="col col-md-5 signin-logo">
                        <img height="236" width="200" src="/images/alf-logo.png" className="forgot-logo" />
                    </div>
                    <div className="col col-md-7 signin-form">
                        <div>
                            {sentMessage && <div className="successMessage">{sentMessage}</div>}
                        </div>

                        <form className="form-horizontal ng-dirty ng-valid-parse ng-valid ng-valid-required" onSubmit={sendEmail}>
                            <fieldset>
                                <div className="form-group">
                                    <span className="glyphicon glyphicon-envelope"></span>
                                    <input 
                                        type="text" 
                                        className="form-control input-lg ng-dirty ng-valid-parse ng-valid ng-valid-required ng-touched" 
                                        required 
                                        autoFocus 
                                        placeholder="Email" 
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        disabled={sentMessage !== ""}
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col col-md-5">
                                            <button 
                                                className="btn btn-primary submit-email" 
                                                type="submit" 
                                                disabled={sentMessage !== ""}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordReset;